import React from 'react';
import './thumbnail-button.css';

export const ThumbnailButton = ({ src, onClick }) => {

    const handleOnClick = () => {
        onClick(src);
    };

    return (
        <button className="thumbnail-button" onClick={handleOnClick}>
            <img src={src} alt="" />
        </button>
    );
};

export default ThumbnailButton;
