import React from "react"
import { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import ThumbnailButton from "../../components/ThumbnailButton"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import './studio-shots.css'

const IMAGE_URL = 'https://aight-images.s3.amazonaws.com/moods/';

const imageNames = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'];

const Mood1 = () => {
    const [modalImage, setModalImage] = useState(null);
    

    const handleCloseModal = () => setModalImage(null);

    return (
        <Layout>
            <SEO title="Mood - studio" />
            <h1>Mood Board - Studio shots</h1>
            <div>
                <section className="thumbnail-layout">
                    {imageNames.map((name) => (
                        <ThumbnailButton
                            key={name}
                            src={`${IMAGE_URL}${name}.jpg`}
                            onClick={(img) => {
                                console.log('>>>>> called');
                                setModalImage(img);
                            }}
                        />
                    ))}
                </section>
                {modalImage && (
                    <div className="image-modal">
                        <div className="image-modal__img-wrapper">
                            <img src={modalImage} alt="" />
                            <button 
                                class="image-modal__close-button"
                                onClick={handleCloseModal}
                            >
                                X
                            </button>
                        </div>
                    </div>
                )}
            </div>
            
            
        </Layout>
    );
};

export default Mood1
